import React, { PureComponent, createRef } from 'react';
import classNames from 'classnames';
import Tag from 'components/tag';

import defaultImg from 'components/assets-image/user/default-avatar.png';
import * as PropTypes from 'prop-types';

/**
 * 用户头像组件
 * @description 当头像地址无法正常加载的时候，fallback 到默认头像
 * @param {string} src
 * @param {string} className
 */
export class Avatar extends PureComponent {

    constructor(props) {
        super(props);

        this.imgEle = createRef();
        this.onError = this.onError.bind(this);
    }

    onError(event) {
        this.imgEle.current.src = this.props.defaultImg;
    }

    render() {

        const { props } = this;
        return (
            <Tag.img
                className={classNames('avatar-img', props.className)}
                src={props.src || ''}
                ref={this.imgEle}
                onErrorCapture={this.onError}
                aria-hidden={true}
            />
        );
    }
}

Avatar.propTypes = {
    src: PropTypes.any,
    className: PropTypes.any
};

// 图片 404 时使用默认头像。
Avatar.defaultProps = {
    defaultImg,
    src: ''
};