
export const initialState = {
  isShowLogin: false,
};

export const reducer = (_, action) => {
  switch (action.type) {
  case 'showLogin':
    return { isShowLogin: true };
  case 'hideLogin':
    return { isShowLogin: false };
  default:
    throw new Error();
  }
};
